exports.onRouteUpdate = ({ location }) => handleOnRouteUpdate(location);

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function handleOnRouteUpdate(location) {
  handleUtmParams(location);
  scrollToAnchor(location);
}

function scrollToAnchor(location) {
  // Check for location so build does not fail
  setTimeout(() => {
    if (location && location.hash) {
      const div = document.querySelector(`${location.hash}`);

      if (div) {
        div.open = true;
        div.classList.add('focused');

        const yOffset = -100;
        const y = div.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        });

        setTimeout(function () {
          div.classList.remove('focused');
        }, 3000);
      }
    }
  }, 1000);

  return true;
}

function handleUtmParams(location) {
  if (location.search) {
    let data = location.search
      .replace(/(^\?)/, '')
      .split('&')
      .map(
        function (n) {
          return (n = n.split('=')), (this[n[0]] = n[1]), this;
        }.bind({}),
      )[0];
    if (data.utm_campaign) {
      pushEvent(data.utm_campaign, data);
    }
  }
}

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}

function detectRobot(userAgent) {
  var robots = new RegExp(
    [
      /bot/,
      /spider/,
      /crawl/, // GENERAL TERMS
      /APIs-Google/,
      /AdsBot/,
      /Googlebot/, // GOOGLE ROBOTS
      /mediapartners/,
      /Google Favicon/,
      /FeedFetcher/,
      /Google-Read-Aloud/,
      /DuplexWeb-Google/,
      /googleweblight/,
      /bing/,
      /yandex/,
      /baidu/,
      /duckduck/,
      /yahoo/, // OTHER ENGINES
      /ecosia/,
      /ia_archiver/,
      /facebook/,
      /instagram/,
      /pinterest/,
      /reddit/, // SOCIAL MEDIA
      /slack/,
      /twitter/,
      /whatsapp/,
      /youtube/,
      /semrush/, // OTHER
    ]
      .map((r) => r.source)
      .join('|'),
    'i',
  ); // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent);
}

function initThirdParties() {
  initGTM();
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  var userAgent = window.navigator.userAgent;
  var isRobot = detectRobot(userAgent);

  if (!isRobot) {
    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-PWG26XV`;

    script.onload = function () {
      pushEvent();
    };

    document.head.appendChild(script);
  }
}

function mergeObjects(obj1, obj2) {
  return Object.assign(obj1, obj2);
}

function pushEvent(name, data) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    var defaultObj = {
      event: name ? 'Campanha: ' + name : 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    };
    var dados = data ? mergeObjects(defaultObj, data) : defaultObj;
    window.dataLayer.push(dados);
  }
}

exports.onClientEntry = function () {
  // Carrega GTM apenas em produção
  if (process.env.NODE_ENV === 'production') {
    document.onreadystatechange = function () {
      console.log('onreadystatechange');
      if (document.readyState !== 'loading') setTimeout(initThirdParties, 1000);
    };

    document.addEventListener('scroll', initGTMOnEvent);
    document.addEventListener('mousemove', initGTMOnEvent);
    document.addEventListener('touchstart', initGTMOnEvent);
  }
};
